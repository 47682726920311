<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-0">
        {{ questionText }}
      </p>
      <v-radio-group v-model="inputs.input1" :disabled="!allowEditing" class="mt-1 pl-6">
        <div v-for="option in options1" :key="option.value">
          <v-radio class="d-inline-block" :value="option.value">
            <template #label>
              <stemble-latex :content="option.text" />
            </template>
          </v-radio>
        </div>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'UPEISafety10',
  components: {StembleLatex},
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
      },
    };
  },
  computed: {
    questionNumber() {
      return this.taskState.getValueBySymbol('questionNumber').content;
    },
    questionText() {
      if (this.questionNumber.value === 1) {
        return 'What type of graph paper is used in lab?';
      } else if (this.questionNumber.value === 2) {
        return 'Where do you write your lab reports in your notebook?';
      } else if (this.questionNumber.value === 3) {
        return 'Notebook records must be written in...';
      } else if (this.questionNumber.value === 4) {
        return 'Do the pages of your notebook need to be numbered?';
      } else if (this.questionNumber.value === 5) {
        return 'What style of writing is accepted in the notebook?';
      } else {
        return 'Where will your reports be submitted?';
      }
    },
    options1() {
      if (this.questionNumber.value === 1) {
        return [
          {text: 'Any type', value: '1'},
          {text: '1 cm square sheets', value: '2'},
          {text: 'Quad 10 to 1 cm sheets', value: '3'},
        ];
      } else if (this.questionNumber.value === 2) {
        return [
          {text: 'Left hand page', value: '1'},
          {text: 'Both sides of the pages', value: '2'},
          {text: 'Right hand page', value: '3'},
        ];
      } else if (this.questionNumber.value === 3) {
        return [
          {text: 'Pen', value: '1'},
          {text: 'Pencil', value: '2'},
          {text: 'Crayon', value: '3'},
        ];
      } else if (this.questionNumber.value === 4) {
        return [
          {text: 'Yes', value: '1'},
          {text: 'No', value: '2'},
          {text: 'Only the first page of each experiment', value: '3'},
        ];
      } else if (this.questionNumber.value === 5 || this.questionNumber.value === 6) {
        return [
          {text: 'First person, present tense', value: '1'},
          {text: 'First person, past tense ', value: '2'},
          {text: 'First person, future tense', value: '3'},
          {text: 'Third person, present tense', value: '4'},
          {text: 'Third person, past tense ', value: '5'},
          {text: 'Third person, future tense', value: '6'},
        ];
      }
    },
  },
};
</script>
